/* eslint-disable */
export class BalanceDictionary {
    // Balance types
    // System balance
    static BALANCE_TYPE_TRANSFER: number = 1; // +, 2targets
    static BALANCE_TYPE_TAKE: number = 2; // -, 2targets
    static BALANCE_TYPE_DEPOSIT: number = 3; // deposit +
    static BALANCE_TYPE_OUT: number = 4; // out -
    static BALANCE_TYPE_REDUCE: number = 5; // game bet / ticket, -
    static BALANCE_TYPE_ADD: number = 6; // game won, +
    static BALANCE_TYPE_REFUND: number = 7; // failing game, partial chance won or game result is not valid +
    static BALANCE_TYPE_SCEDUAL_REDUCE: number = 8; // zero User.balance, -, 2targets
    static BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE: number = 9; // zero User.scedualPaymentBalance, -, 2targets
    // static BALANCE_TYPE_SCEDUAL_PAYMENT: number = 10; // sced payment +
    // Providers balance
    static BALANCE_TYPE_RACING_DEPOSIT: number = 30; // 0, +
    static BALANCE_TYPE_RACING_WITHDRAW: number = 31; // 1, -
    // live-casino type
    static BALANCE_TYPE_CASINO_DEBIT: number = 40; // -
    static BALANCE_TYPE_CASINO_CREDIT: number = 41; // +
    static BALANCE_TYPE_CASINO_ROLLBACK: number = 42; // +-

    static BALANCE_TYPE_SPORT_DEBIT: number = 50; // -
    static BALANCE_TYPE_SPORT_CREDIT: number = 51; // +
    static BALANCE_TYPE_SPORT_ROLLBACK: number = 52; // +-
    // live-casino providerType
    static BALANCE_TYPE_CASINO_PLACE_BET: number = 400; // 2.2 placeBet -
    static BALANCE_TYPE_CASINO_GAME_RESULT_WIN: number = 401; // 2.3 Game Result (default: win +; direct resettlement -) +
    static BALANCE_TYPE_CASINO_GAME_RESULT_DIRECT_RESETTLEMENT: number = 402; // -
    static BALANCE_TYPE_CASINO_ROLLBACK_WIN: number = 403;
    // 2.4 Rollback (default: cancel win -; Bet Adjustment / bet correct +, Reverse Bet / cancel Bet +-) -
    static BALANCE_TYPE_CASINO_ROLLBACK_BET_ADJUSTMENT: number = 404; // +
    static BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE_POSITIVE: number = 405; // +-, +
    static BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE: number = 406; // +-, -
    static BALANCE_TYPE_CASINO_CANCEL_BET: number = 407; // 2.5 cancelBet +
    static BALANCE_TYPE_CASINO_BONUS: number = 408; // 2.6 bonus +
    static BALANCE_TYPE_CASINO_JACKPOT: number = 409; // 2.7 jackpot +
    static BALANCE_TYPE_CASINO_BUY_IN: number = 410; // 2.9 buyIn -
    static BALANCE_TYPE_CASINO_BUY_OUT: number = 411; // 2.10 buyOut +
    static BALANCE_TYPE_CASINO_PUSH_BET: number = 412; // 2.11
    // sport providerType
    static BALANCE_PROVIDER_TYPE_SPORT_DEBIT: number = 500;
    static BALANCE_PROVIDER_TYPE_SPORT_CREDIT: number = 501;
    static BALANCE_PROVIDER_TYPE_SPORT_ROLLBACK: number = 502;

    // Balance-out status
    static BALANCE_OUT_STATUS_CREATED: number = 1;
    static BALANCE_OUT_STATUS_REJECTED: number = 2;
    static BALANCE_OUT_STATUS_APPROVED: number = 3;
    static BALANCE_OUT_STATUS_FINISHED: number = 4;
}

export enum BalanceType {
    TRANSFER = BalanceDictionary.BALANCE_TYPE_TRANSFER,
    TAKE = BalanceDictionary.BALANCE_TYPE_TAKE,
    DEPOSIT = BalanceDictionary.BALANCE_TYPE_DEPOSIT,
    OUT = BalanceDictionary.BALANCE_TYPE_OUT,
    REDUCE = BalanceDictionary.BALANCE_TYPE_REDUCE,
    ADD = BalanceDictionary.BALANCE_TYPE_ADD,
    REFUND = BalanceDictionary.BALANCE_TYPE_REFUND,
    SCEDUAL_REDUCE = BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE,
    SCEDUAL_REDUCE_BALANCE = BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE,
    RACING_DEPOSIT = BalanceDictionary.BALANCE_TYPE_RACING_DEPOSIT,
    RACING_WITHDRAW = BalanceDictionary.BALANCE_TYPE_RACING_WITHDRAW,
    CASINO_DEBIT = BalanceDictionary.BALANCE_TYPE_CASINO_DEBIT,
    CASINO_CREDIT = BalanceDictionary.BALANCE_TYPE_CASINO_CREDIT,
    CASINO_ROLLBACK = BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK,
    SPORT_DEBIT = BalanceDictionary.BALANCE_TYPE_SPORT_DEBIT,
    SPORT_CREDIT = BalanceDictionary.BALANCE_TYPE_SPORT_CREDIT,
    SPORT_ROLLBACK = BalanceDictionary.BALANCE_TYPE_SPORT_ROLLBACK,
}

export enum BalanceProviderType {
    CASINO_PLACE_BET = BalanceDictionary.BALANCE_TYPE_CASINO_PLACE_BET,
    CASINO_GAME_RESULT_WIN = BalanceDictionary.BALANCE_TYPE_CASINO_GAME_RESULT_WIN,
    CASINO_GAME_RESULT_DIRECT_RESETTLEMENT = BalanceDictionary.BALANCE_TYPE_CASINO_GAME_RESULT_DIRECT_RESETTLEMENT,
    CASINO_ROLLBACK_WIN = BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_WIN,
    CASINO_ROLLBACK_BET_ADJUSTMENT = BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_BET_ADJUSTMENT,
    CASINO_ROLLBACK_BET_REVERSE_POSITIVE = BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE_POSITIVE,
    CASINO_ROLLBACK_BET_REVERSE = BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE,
    CASINO_CANCEL_BET = BalanceDictionary.BALANCE_TYPE_CASINO_CANCEL_BET,
    CASINO_BONUS = BalanceDictionary.BALANCE_TYPE_CASINO_BONUS,
    CASINO_JACKPOT = BalanceDictionary.BALANCE_TYPE_CASINO_JACKPOT,
    CASINO_BUY_IN = BalanceDictionary.BALANCE_TYPE_CASINO_BUY_IN,
    CASINO_BUY_OUT = BalanceDictionary.BALANCE_TYPE_CASINO_BUY_OUT,
    CASINO_PUSH_BET = BalanceDictionary.BALANCE_TYPE_CASINO_PUSH_BET,
    TYPE_SPORT_DEBIT = BalanceDictionary.BALANCE_PROVIDER_TYPE_SPORT_DEBIT,
    TYPE_SPORT_CREDIT = BalanceDictionary.BALANCE_PROVIDER_TYPE_SPORT_CREDIT,
    TYPE_SPORT_ROLLBACK = BalanceDictionary.BALANCE_PROVIDER_TYPE_SPORT_ROLLBACK,
}

export enum SystemProvider {
    SYSTEM_BALANCE = 1,
    PROVIDER_WALLET = 2,
    SYSTEM_GAME = 10,
    PROVIDER_RACING = 11,
    PROVIDER_CASINO = 12,
    PROVIDER_SPORT = 13,
}

export const gameDictionary = {
    // Game status
    GAME_STATUS_CREATED: 1,
    GAME_STATUS_STARTED: 2,
    GAME_STATUS_ENDED: 3,
    GAME_STATUS_FAILED: 4,
    GAME_STATUS_DELETED: 5,

    // Name Game
    GAME_TYPE_BEST_AT_TABLE_NAME: 'Best a table',
    GAME_TYPE_CHECKPOINT_NAME: 'Checkpoint',
    GAME_TYPE_ONE_ON_ONE_NAME: 'One on One',
    GAME_TYPE_MONTH_PRIZE_NAME: 'Month prize',
    GAME_TYPE_DAILY_NAME: 'Daily',
    GAME_TYPE_JACKPOT_NAME: 'Jackpot',

    // Game type
    GAME_TYPE_BEST_AT_TABLE: 1,
    GAME_TYPE_CHECKPOINT: 2,
    GAME_TYPE_ONE_ON_ONE: 3,
    GAME_TYPE_MONTH_PRIZE: 4,
    GAME_TYPE_DAILY: 5,
    GAME_TYPE_JACKPOT: 6,
    GAME_TYPE_CASINO: 7,
    GAME_TYPE_CHANCE: 8,

    // checkpoint point
    GAME_CHECKPOINT_FIRST: 1,
    GAME_CHECKPOINT_SECOND: 2,
    GAME_CHECKPOINT_THIRD: 3,

    // Failed status
    GAME_STATUS_NOT_ENOUGH_PLAYERS: 1,
    GAME_STATUS_NOT_ENOUGH_MATCHES: 2,
    GAME_STATUS_NOT_ENOUGH_PLAYERS_AND_MATCHES: 3,

    // Default value
    GAME_DEFAULT_CASHBACK_PERCENT: 10,
};

export enum EnumValidate {
    VALIDATE_LANG_LENGTH = 2,
    VALIDATE_COUNTRY_LENGTH = 2,
    VALIDATE_CURRENCY_LENGTH = 3,
    VALIDATE_CODE_LENGTH = 8,
    VALIDATE_STRING_LENGTH_MIN = 2,
    VALIDATE_STRING_LENGTH_MAX = 100,
    VALIDATE_SECURE_LENGTH_MIN = 8,
    VALIDATE_SECURE_LENGTH_MAX = 32,
    VALIDATE_SECURE_DB_LENGTH_MAX = 100,
    VALIDATE_PAYLOAD_DB_LENGTH_MAX = 500,
    VALIDATE_LOGIN_LENGTH_MIN = 4,
    VALIDATE_LOGIN_LENGTH_MAX = 32,
    VALIDATE_EMAIL_LENGTH_MIN = 6,
    VALIDATE_EMAIL_LENGTH_MAX = 254,
    VALIDATE_PHONE_LENGTH_MIN = 10,
    VALIDATE_PHONE_LENGTH_MAX = 15,
    VALIDATE_PASSWORD_LENGTH_MIN = 4,
    VALIDATE_PASSWORD_LENGTH_MAX = 32,
    VALIDATE_NAME_LENGTH_MIN = 4,
    VALIDATE_NAME_LENGTH_MAX = 500,
}

export enum GlobalGameTypeEnum {
    'chance' = 500,
    'chance-speed' = 501,
    'livecasino' = 2,
    'poker' = 999,
    'virtual-sports' = 3,
    'sportsbook' = 4,
    'live-casino-table' = 5,
    'video-slots' = 6,
    'table-games' = 7,
    'video-poker' = 8,
    'virtual-games' = 9,
    'scratch-cards' = 10,
    'video-bingo' = 11,
    'tournaments' = 12,
    'livegames' = 13,
    'crash-games' = 14,
    'fast-games' = 15,
    'OT' = 777,
}
