export const configData = {
    REACT_APP_API_URL: process.env.REACT_APP_API_URL || sessionStorage.getItem('REACT_APP_API_URL'),
};

export const loadConfig = async () => {
    try {
        const response = await fetch('./config.json');
        const config = await response.json();
        sessionStorage.setItem('REACT_APP_API_URL', config.REACT_APP_API_URL);
        configData.REACT_APP_API_URL = config.REACT_APP_API_URL;
        return config;
    } catch (e) {
        console.log('Failed to load config.json', e);
    }
};
